import React, { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Heart from "../components/heart";
import queryString from "query-string";

function Share(props) {
  const [count, setCount] = useState(0);
  const [jazzHands, setJazzHands] = useState([]);
  const search = props.location.search
    ? queryString.parse(props.location.search)
    : {};
  const hearts = parseFloat(search.hearts) || 0;
  let bodyRef = React.createRef(null);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(() => {
    const body = bodyRef.current
      ? bodyRef.current.getBoundingClientRect()
      : { width: document.scrollX, height: document.scrollY };
    let array = jazzHands;
    let bodyX = Math.random() * body.width;
    if (jazzHands.length > 10) {
      array.splice(0, 1);
    }
    setJazzHands([
      ...array,
      {
        id: count,
        x: bodyX,
        x2: bodyX - bodyX / 2,
        y: body.height - 100,
        scale: Math.random() * (1 - 0.3) + 0.3
      }
    ]);
    setCount(count + 1);
  }, 1000);

  return (
    <Layout
      title="Rädda en stockholmare"
      ogTitle="Rädda en stockholmare"
      desc="Jag har skickat handhjärtan som tröst."
      className="layout"
    >
      <div className="wrap wrap-share" ref={bodyRef}>
        <div className="bg-wrap"></div>
        <div className="shareText">
          <h1>
            Någon har skickat {hearts}{" "}
            {hearts > 1 || !hearts ? "handhjärtan" : "handhjärta"} till en
            stockholmare
          </h1>
          <a href="https://räddaenstockholmare.se">Läs mer</a>
        </div>
        <div class="totalWrap">
          <Heart
            ease="easeInOut"
            items={jazzHands}
            image={props.data.jazzHands.childImageSharp.fluid}
            draggable="false"
          />
        </div>
      </div>
    </Layout>
  );
}

export default Share;

export const pageQuery = graphql`
  query {
    jazzHands: file(relativePath: { regex: "//jazzhands.png/gm" }) {
      childImageSharp {
        fluid(maxWidth: 125) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
